import React, { useState, useEffect } from "react";
import Bounce from "react-reveal/Bounce";

const experienceDetails = [
  {
    experienceType: "PART TIME",
    jobTitle: "Graduate Research Assistant",
    companyLogo:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/The_University_of_California_Davis.svg/640px-The_University_of_California_Davis.svg.png",
    companyName: "University of California, Davis",
    duration: "June 2024 - Present",
    descrption: [
      "Engineered advanced 2D Convolutional Neural Networks (CNNs) using Pytorch to classify time-series ECG data from diverse chart images, boosting model accuracy through data transformation and targeted image augmentation techniques.",
      "Created an automated experimentation framework in Python to optimize model training, leveraging Optuna for hyperparameter tuning and Tensorboard for performance monitoring, which improved model robustness and cut training time.",
      "Constructed an ensemble model utilizing various CNN architectures and chart image types to increase Balanced Accuracy; currently preparing a thesis and research paper on these advancements in time-series data analysis.",
    ],
    skills: "",
  },
  {
    experienceType: "PART TIME",
    jobTitle: "Teaching Assistant",
    companyLogo:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/The_University_of_California_Davis.svg/640px-The_University_of_California_Davis.svg.png",
    companyName: "University of California, Davis",
    duration: "September 2023 - Present",
    descrption: [
      "Implemented innovative teaching methods, including interactive quizzes and group discussions, to create an immersive and engaging learning environment, and coordinated student-centric activities like debates to enhance the educational experience.",
      "Provided detailed and constructive feedback on assignments and exams, and conducted weekly office hours for personalized student guidance, significantly contributing to student academic performance and success.",
    ],
    skills: "",
  },
  {
    experienceType: "INTERNSHIP",
    jobTitle: "Research & Development",
    companyLogo: "https://upload.wikimedia.org/wikipedia/en/f/fe/Srmseal.png",
    companyName: "SRM University",
    duration: "Dec 2022 - Apr 2023",
    descrption: [
      "Under the mentorship of Dr. Jackulin Mahariba, engineered a sophisticated health platform tailored for eldercare, integrating 	patient identification via Flask to bolster data security and access.",
      "Streamlined complex algorithms by reducing redundant parameters, applying statistical methods such as correlation analysis for 	enriched data efficiency and analysis in Python and SQL, improving accuracy by 5%.",
      "Refined and tuned six advanced machine learning models using Scikit-learn, achieving significant enhancements in predictive accuracy and performance, utilizing correlation coefficients for more efficient and effective data analysis.",
      "Applied Seaborn and Matplotlib for Exploratory Data Analysis, aiding in the transparent communication of complex health data insights to non-technical stakeholders.",
    ],
    skills:
      "Matplotlib · Exploratory · Data Analysis · Correlation · Coefficients",
  },
  {
    experienceType: "INTERNSHIP",
    jobTitle: "Research & Development",
    companyLogo: "https://upload.wikimedia.org/wikipedia/en/f/fe/Srmseal.png",
    companyName: "SRM University",
    duration: "July 2022 - Nov 2022",
    descrption: [
      "Devised a solution to digitize the waste management system around the campus of 250 acres.",
      "Configured an IoT device to transmit signal that gives live update of all the garbage cans around the campus.",
      "Used Machine Learning algorithms to provide an optimized solution to minimize garbage stray.",
      "Implementation of digital charts by using Data Visualization tools like Tableau.",
      "Guided under the Mentorship of Dr P Supraja.",
    ],
    skills: "Tableau · Machine Learning algorithms",
  },
  {
    experienceType: "INTERNSHIP",
    jobTitle: "Research Work",
    companyLogo: "https://upload.wikimedia.org/wikipedia/en/f/fe/Srmseal.png",
    companyName: "SRM University",
    duration: "Apr 2022 - June 2022",
    descrption: [
      "Analyzed object detection capabilities across various architectures using the COCO dataset, with findings recommending Darknet 	for its pre-trained weights and optimal performance for real-time applications.",
      "Evaluated and documented the performance trade-offs between YOLO v5, v3, ResNet, and Tiny Yolo, contributing to academic and practical understanding of machine learning model efficiency.",
    ],
    skills: "YOLO · ResNet · Tiny Yolo",
  },
  {
    experienceType: "INTERNSHIP",
    jobTitle: "Machine Learning",
    companyLogo:
      "https://qph.cf2.quoracdn.net/main-qimg-5a970482be5640c2e44866a4aeed36f3",
    companyName: "Verzeo Pvt Ltd. ",
    duration: "Feb 2021 - Apr 2021",
    descrption: [
      "Developed and architected a model, which classified a data set of 2000+ images, according to the ethnicity.",
      "Optimized the model by 50% increasing the accuracy to 76%.",
    ],
    skills: "",
  },
  // {
  //   experienceType: "INTERNSHIP",
  //   jobTitle: "Business Analyst",
  //   companyLogo: "",
  //   companyName: "Inspirogen Pvt Ltd",
  //   duration: "Nov 2021 - Feb 2022",
  //   descrption: [
  //     "Supervised recruitment team of 20+ members and scheduled on-site interviews and headed all recruitment related events.",
  //     "Shortlisted 20+ résumé and forwarded it to corporate-heads for their consideration.",
  //     "Spearheaded a team of 50+ HR members, across 4 locations globally.",
  //   ],
  //   skills: "",
  // },
  {
    experienceType: "COLLEGE ORGANIZATION",
    jobTitle: "Head of Technical Domain",
    companyLogo:
      "https://media.licdn.com/dms/image/C560BAQH619tD8PgrsQ/company-logo_200_200/0/1662543653252/salesforce_student_group_logo?e=2147483647&v=beta&t=qbYgGYciQJI2j9FoP3qe4SzSBWtkWbwUvACWqcSyoUs",
    companyName: "Salesforce Student Chapter SRM",
    duration: "June 2022 - May 2023",
    descrption: [
      "Organized workshops for the internal Team.",
      "Trained the team for external workshops which had over 200+ participants.",
    ],
    skills:
      "Conflict Management · Adaptability · Leadership · Resourcefulness · Time management",
  },
  {
    experienceType: "COLLEGE ORGANIZATION",
    jobTitle: "Head of the SRM Bhangra Dance Team",
    companyLogo: "https://upload.wikimedia.org/wikipedia/en/f/fe/Srmseal.png",
    companyName: "JattSquad Bhangra SRM",
    duration: "Apr 2022 - May 2023",
    descrption: [
      "Organized workshops for the internal Team.",
      "Trained the team for external workshops which had over 200+ participants.",
    ],
    skills:
      "Conflict Management · Choreography · Recruitment · Leadership · Resourcefulness · Time management",
  },
  {
    jobTitle: "",
    companyLogo:
      "https://images.unsplash.com/photo-1538589085637-33f227415e36?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YWNoaWV2ZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    companyName: "Achieving More Out There! 🎯",
    duration: "",
    descrption: [],
  },
];

export default function Experience() {
  const [experience, setExperiemce] = useState("");
  useEffect(() => {
    setExperiemce(experienceDetails);
  }, []);
  return (
    <section id="experience" class="dark">
      <div class="inner-width">
        <h1 className="title">Experience</h1>
        <div class="section-title"></div>
        <div class="services">
          {experience.length > 0 ? (
            experience.map((item) => (
              <Bounce bottom>
                <div className="experience">
                  <h4>{item.experienceType}</h4>
                  <h3>{item.jobTitle}</h3>
                  <div className="exp-head">
                    <img
                      src={`${item.companyLogo}`}
                      className="club-logo"
                      alt={item.companyName}
                    ></img>
                    <h2>{item.companyName}</h2>
                  </div>
                  <div className="content-box">
                    {item.duration}
                    <p className="exp-content">
                      {item.descrption.map((desc) => (
                        <ul type="disc">
                          <li className="span">{desc}</li>
                        </ul>
                      ))}
                    </p>
                    <div className="proj-det">
                      <p className="proj-stack">{item.skills}</p>
                    </div>
                  </div>
                </div>
              </Bounce>
            ))
          ) : (
            <Bounce bottom>
              <div className="experience">
                <h4>
                  Looks Empty? Don't think I got no experience, mgiht be some
                  issue with the servers. Let's have a cup of coffee, maybe I'll
                  blow your minds. ❤️‍🔥
                </h4>
              </div>
            </Bounce>
          )}
        </div>
      </div>
    </section>
  );
}
