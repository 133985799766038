import Slide from "react-reveal/Slide";

export default function Education() {
  return (
    <section id="education" class="dark">
      <div className="inner-width">
        <h1 className="title">Education</h1>
        <div className="section-title"></div>

        <div className="time-line">
          <Slide left>
            {/* <div className="block">
              <h4>Indian School Muscat</h4>
              <h3>2017-2019</h3>
              <p>
                <i>High School Diploma</i>
              </p>
              <p>
                <ul type="disc">
                  <br></br>
                  <li>
                    Represented the Indian School Muscat U-17 Basketball team.
                  </li>
                </ul>
              </p>
            </div> */}
            <div className="block">
              <h4>University of California, Davis</h4>
              <h3>2023-2025</h3>
              <p>
                <i>Master of Science in Computer Science</i>
              </p>
              <p>
                <b>GPA: 4</b>
              </p>
              <p>
                <ul type="disc">
                  <br></br>
                  <li>Graduate Student Researcher</li>
                  <li>Teaching Assistant</li>
                </ul>
              </p>
            </div>
          </Slide>
          <Slide left>
            <div className="block">
              <h4>SRM University</h4>
              <h3>2019 - 2023</h3>
              <p>
                <i>Bachelor of Technology in Computer Science Enginnering </i>
              </p>
              <p>
                {" "}
                <b>GPA: 9.39</b>
              </p>

              <p>
                <ul type="disc">
                  <br></br>
                  <li>Head of Technical at Salesforce Student Chapter SRM</li>
                  <li>Completed and Worked on 3 Research based Projects</li>
                  <li>
                    {" "}
                    Successfully published 2 papers at IEEE Xplore and Springer
                    CCIS
                  </li>
                  <li>Head of SRM University's Bhangra Team</li>
                </ul>
              </p>
            </div>
          </Slide>
          <Slide left>
            <div className="blockmid">
              <h4>Additional Acheivements</h4>
              <p>
                <ul type="disc">
                  <br></br>
                  <li>
                    Diploma in Bharathanatyam (Indian Classical Dance) from
                    Singapore Indian Fine Arts Society (SIFAS)
                  </li>
                  <li>
                    Best Student Award in 5th year (2012) at Singapore Indian
                    Fine Arts Society{" "}
                  </li>
                  <li>
                    Awarded Natya Visharad in 2017 from Singapore Indian Fine
                    Arts Society{" "}
                  </li>
                  <li>
                    Awarded Best Indian Classical Dancer at ASEAN India Pravasi
                    Bharatiya Divas (2018){" "}
                  </li>
                </ul>
              </p>
            </div>
          </Slide>
        </div>
      </div>
    </section>
  );
}
