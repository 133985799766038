import React, { useState, useEffect } from "react";

import Bounce from "react-reveal/Bounce";

import CertificateModal from "../content/certificateModal";

const certificationsDetails = [
  {
    certificateName: "Google Professional Data Analytics",
    certifiedBy: "Google",
    certificateImageSRC:
      "https://kkportfolio-assets.s3.ap-south-1.amazonaws.com/assets/mk+certificates/google.jpeg",
    certificateImageALT: "Google",
  },
  {
    certificateName: "DeepLearning.AI TensorFlow Developer",
    certifiedBy: "DeepLearning.AI",
    certificateImageSRC:
      "https://kkportfolio-assets.s3.ap-south-1.amazonaws.com/assets/mk+certificates/tensorflow.jpeg",
    certificateImageALT: "DeepLearning.AI",
  },
  {
    certificateName: "UCD SQL for Data Science",
    certifiedBy: "University of California, Davis",
    certificateImageSRC:
      "https://kkportfolio-assets.s3.ap-south-1.amazonaws.com/assets/mk+certificates/ucd.jpeg",
    certificateImageALT: "UCD SQL for Data Science",
  },
  {
    certificateName: "Imperial College Mathematics for Machine Learning",
    certifiedBy: "Imperial College",
    certificateImageSRC:
      "https://kkportfolio-assets.s3.ap-south-1.amazonaws.com/assets/mk+certificates/imperial.jpeg",
    certificateImageALT: "Imperial College",
  },
  // {
  //   certificateName: "Career Edge - Knockdown the Lockdown",
  //   certifiedBy: "Tata Consultancy Services",
  //   certificateImageSRC:
  //     "https://kkportfolio-assets.s3.ap-south-1.amazonaws.com/images/logo/tata.jpg",
  //   certificateImageALT: "Tata Consultancy Services",
  // },
];

export default function Certification() {
  const [certificate, setCertificate] = useState("");

  useEffect(() => {
    setCertificate(certificationsDetails);
  }, []);

  return (
    <section id="certification">
      <div className="inner-width">
        <h1 className="title">Certification</h1>
        <div className="section-title"></div>
        <div className="time-line">
          {certificate.length > 0 ? (
            certificate.map((item) => (
              <Bounce bottom>
                <div className="blockcertif">
                  <div className="info1">
                    <img
                      src={`${item.certificateImageSRC}`}
                      alt={`${item.certificateImageALT}`}
                      className="icon"
                    />
                    <div className="info2">
                      <CertificateModal props={item} />
                    </div>
                  </div>
                  <h3> {item.certificateName}</h3>
                  <h6>{item.certifiedBy}</h6>
                </div>
              </Bounce>
            ))
          ) : (
            <Bounce bottom>
              <div className="blockcertif">
                <h3>
                  Hey, Don't see any certificates? Hold on right there!
                  <br />
                  <br />
                  I got plenty to display, please check out my resume to see all
                  of them. They might be down due to servers.
                  <br />
                  <br />
                  PS: Feel free to Hit Me Up for Funding my Servers xD!
                </h3>
              </div>
            </Bounce>
          )}
        </div>
      </div>
    </section>
  );
}
