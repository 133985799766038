export default function About() {
  return (
    <section id="about">
      <div class="inner-width">
        <h1 className="title">About</h1>
        <h1 className="section-title"></h1>
        <div className="about-content">
          <img
            src="https://kkportfolio-assets.s3.ap-south-1.amazonaws.com/assets/kkpic/mine.jpeg"
            alt="Kaushik here!"
            className="about-pic"
          />
          <div className="about-text">
            <h2> Hi! I'm Madhumitha.</h2>
            <br></br>
            <h3>
              <span>Passionate</span>
              <span>Progressive</span>
              <span>Persistent</span>
            </h3>
            <p>
              I am deeply passionate about technology and its constant
              evolution. My interest goes beyond academic pursuit—it's a
              personal commitment to staying updated with the latest tech
              trends. With a keen focus on practical applications, I enjoy
              exploring innovative solutions to real-world problems. My journey
              in technology is driven by a strong desire to learn, adapt, and
              contribute effectively to the ever-changing tech landscape. In my
              career, I aim to blend my technical skills with creative
              problem-solving to make a tangible impact in the field.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
