import Bounce from "react-reveal/Bounce";
import { useEffect, useState } from "react";

const projectDetails = [
  {
    projectImageURL:
      "https://kkportfolio-assets.s3.ap-south-1.amazonaws.com/assets/project-rev/schduler.png",
    projectImageALT: "LLM",
    projectName: "NLP-Based News Research LLM Application",
    projectGitLink:
      "https://github.com/vmadhuuu/LLM-Projects/tree/main/NewsLLM",
    projectSourceLink: "",
    projectDescription:
      "Developed a News Research Tool using Streamlit, Langchain, and FAISS with OpenAI embeddings for real-time question-answering from unstructured data, improving research efficiency. Designed a data processing pipeline with UnstructuredURLLoader, RecursiveCharacterTextSplitter, and vectorization, enhancing retrieval accuracy",
    Techstack:
      "Streamlit · Langchain · FAISS · RecursiveCharacterTextSplitter · vectorization",
  },
  {
    projectImageURL:
      "https://kkportfolio-assets.s3.ap-south-1.amazonaws.com/assets/project-rev/schduler.png",
    projectImageALT: "Building ETL Pipeline",
    projectName: "PixelPulse, Interactive Video Game Sales Analyticse",
    projectGitLink: "https://github.com/vmadhuuu/ECS272-PixelPulseFinalProject",
    projectSourceLink: "",
    projectDescription:
      "Developed an interactive dashboard for video game sales analytics using React and D3.js, transforming raw sales data into structured formats. Used Treemaps, Sankey diagrams, and Heatmaps to process large datasets for advanced analytics and insights.",
    Techstack: "React.js · D3.js · Data Processing",
  },
  {
    projectImageURL:
      "https://kkportfolio-assets.s3.ap-south-1.amazonaws.com/assets/project-rev/schduler.png",
    projectImageALT: "RL",
    projectName: "Dynamic Pricing with Reinforcement Learning for Ride Sharing",
    projectGitLink: "https://github.com/vmadhuuu/Dynamic-Pricing",
    projectSourceLink: "",
    projectDescription:
      "Engineered a reinforcement learning model using Deep Q-Network (DQN) with Stable Baselines3 to dynamically adjust ride-sharing fares in real-time, optimizing pricing strategies with historical data. Deployed the model via FastAPI, improving prediction accuracy by 11% and enhancing revenue optimization with accurate fare adjustments.",
    Techstack: "Deep Q-Network (DQN)  · Baselines3 · FastAPI · Flask",
  },
  {
    projectImageURL:
      "https://kkportfolio-assets.s3.ap-south-1.amazonaws.com/assets/project-rev/schduler.png",
    projectImageALT: "Geriatrify,",
    projectName: "Customer Churn Prediction with ML and Flask",
    projectGitLink: "https://github.com/vmadhuuu/Customer-Churn-Prediction",
    projectSourceLink: "",
    projectDescription:
      "Performed EDA and data cleaning to build a customer churn prediction model using Logistic Regression and XGBoost, increasing recall by 15% with feature engineering and SMOTE for imbalanced data. Designed a Flask-based web application with 5-fold cross-validation and automated preprocessing, enabling real-time predictions and improving retention insights..",
    Techstack: "Flask · Logistic Regression · XGBoost · SMOTE · Automation · ",
  },
];

export default function Experience() {
  const [projectDetail, setProjectDetail] = useState("");
  useEffect(() => {
    setProjectDetail(projectDetails);
  }, []);
  return (
    <section id="works" class="dark">
      <div class="inner-width">
        <h1 className="title">Projects</h1>
        <div class="section-title"></div>
        <div class="services">
          {projectDetail.length > 0 ? (
            projectDetail.map((detail) => (
              <Bounce bottom>
                <div className="project">
                  <div className="proj-container">
                    <div className="proj-head">
                      <h3>{detail.projectName}</h3>
                      <div className="proj-visit">
                        <span className="proj-link-body">
                          <a
                            className="proj-link1"
                            target="_blank"
                            href={detail.projectGitLink}
                          >
                            {" "}
                            GitHub <i class="fab fa-github"></i>
                          </a>
                        </span>
                        {/* <span className="proj-link-body">
                          <a
                            className="proj-link2"
                            target="_blank"
                            href={detail.projectSourceLink}
                          >
                            {" "}
                            View <i class="fas fa-external-link-alt"></i>
                          </a>
                        </span> */}
                      </div>
                    </div>
                    <div className="proj-content">
                      <p>{detail.projectDescription}</p>
                      {/* <p className="tech-head">TechStack:</p> */}
                      <div className="proj-det">
                        <p className="proj-stack">{detail.Techstack}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Bounce>
            ))
          ) : (
            <Bounce bottom>
              <div className="project">
                <div className="proj-container">
                  <div className="proj-head">
                    <h3>
                      Do not see any projects here? Might be something tweaky
                      wrong with the servers, please check out my GitHub
                      profile!
                    </h3>
                  </div>
                </div>
              </div>
            </Bounce>
          )}
        </div>
      </div>
    </section>
  );
}
